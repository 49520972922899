#status-header {
    text-align: center;
}

.sessions-list-container {

    height: 54vh;
    width: 100%;

    .expand-btn {
        margin-right: 20px;
    }

    .list-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .list-item-properties {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .list-item-name {
                width: 20%;
                margin-left: 20px;
            }


            
            .list-item-secondary {
                width: 20%;
                z-index: 200;
                display: flex;
                justify-content: flex-start;

                .attendance {
                    height: 90%;
                    min-width: 10rem;
                    border-radius: 10px;
                    color: white;
                    font-weight: 500;
                    padding: 0 .5rem 0 .5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // margin-left: auto;
                    // margin-right: auto;
                }

                .attendance-controller {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
    
    .delete-btn{
        width: 30px;
        height: 30px;
        margin-right: 30px;
        display: flex;
    }

    .status-green {
        background-color: lightgreen;
    }

    .status-red {
        background-color: salmon;
    }

    .status-yellow {
        background-color: lightyellow;
    }
}

