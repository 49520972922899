@import '../../../style/utilities.scss';


.class-header {
    padding-left: 1.3rem;
    color: color(black);
    box-shadow: 2px 2px 2px rgba(0,0,0,0.06), 0 4px 4px rgba(0,0,0,0.06);

    .navbar-container {
        margin-top: 1rem;
    
        a {
            margin: 0 2rem 0 0;
        }
    
        .nested-active-route {
            padding: .4rem 1rem .4rem 1rem;
            font-weight: 600;
            color: color(red);
            border-radius: 30px;
            background-color: rgba(0,0,0,0.01);
            box-shadow: 2px 2px 2px rgba(0,0,0,0.06), 0 4px 4px rgba(0,0,0,0.06);
        }
    }

    .description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        height: 1.5rem;

        .pin {
            width: 1rem;
            height: 1rem;
        }

        .content {
            font-weight: 300;
            margin-left: .5rem;
            color: color(light-black);
        }
    }
}