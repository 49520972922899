#invoice-action {
    justify-content: flex-end;
}

#invoice-form {
    padding: 0 2rem 4rem 2rem;
}

.invoice-action-container {
    display: flex;
    flex-direction: column;

    .invoice-btn {
        margin-bottom: .5rem;
    }
}

.customer-information-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    flex-wrap: wrap;
    // margin-bottom: 1.5rem;
    // justify-content: space-between;

    .field {
        width: 24%;
        margin-right: 1%;
        margin-top: 1rem;
    }
    .dense-field {
        width: 15%;
        margin-right: 1%;
        margin-top: 1rem;
    }

    .custom-field {
        width: 15%;
        margin-right: 1%;
    }
}

.services-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .service-table {
        width: 100%;

        th, td {
            padding: .5rem;
            text-align: left;
            vertical-align: top;
        }

        td:first-child {
            width: 16%
        }

        td:nth-child(2) {
            width: 16%;
        }
        td:nth-child(3) {
            width: 16%;
        }

        td {
            font-size: .7rem;
            font-weight: 400;
        }

        .add-package-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        .table-input {
            width: 100%;
            border: 1px solid #bdc3c7;
            min-height: 2rem;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: .5rem;
        }

        .minus-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;
            outline: none;
            border: none;
            color: #e74c3c;
            font-size: 1.3rem;
            background-color: white;
            border-radius: 50%;
            cursor: pointer;
        }

        .input-wrapper {
            width: 100%;
            border: 1px solid #bdc3c7;
            height: 2rem;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: .5rem;
        }

        .input {
            width: 100%;
            margin-left: .5rem;
            box-shadow: none;
            outline: none;
            border: none;
            font-size: .8rem;
        }
    }
}

.totals-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;

    .totals {
        width: 25%;
        // margin-right: 10rem;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: .5rem 0 .5rem 0;

            .total-title {
                margin: 0
            }
            .total-num {
                margin: 0
            }
        }

        .discount {
            width: 50%;
            border: 1px solid #bdc3c7;
            height: 2rem;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: .5rem;
            outline: none;
        }
    }
}

.discount-wrapper {
    width: 100%;
    border: 1px solid #bdc3c7;
    height: 2rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: .5rem;

    .input {
        width: 100%;
        margin-left: .5rem;
        box-shadow: none;
        outline: none;
        border: none;
        font-size: .8rem;
    }
}

.children-container {
    margin-top: .5rem;
    margin-left: .2rem;
    margin-bottom: 1rem;

    .children {
        margin: 0;
        font-size: .8rem;
    }
}

.additional-notes-input {
    border: 1px solid #bdc3c7;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: .5rem;
    font-size: 1rem;
}

#total-discount {
    width: 50%;
}

.select-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 2rem;
    border-radius: 5px;
    border: 1px solid #bdc3c7;
    
}

.select-container select {
    border: none;
    background: transparent;
    padding: .5rem;
    width: 100%;
    outline: none;
}