.class-grid-container {
    height: 70vh;
    width: 100%;
}

.students-in-class-container {
    color: #1e90ff;
    cursor: pointer;

    .pop-over {
        z-index: 1000000;
        position: relative;
    }
}

.students-in-class-container:hover {

}