@import '../../../style/utilities.scss';


.dashboard-container {
    opacity: 0;
    transition: opacity .3s;

    .widgets-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: -.5rem;
        margin-top: 1rem;
    }
}

.dashboard-container.active {
    opacity: 100;
}