@import '../../../style/utilities.scss';

.class-event-wrapper {
    width: 90%;
    padding: 1rem 0 1rem 0;
    position: relative;

    .class-event-container {
        width: 100%;
        min-height: auto;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 10;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: .5rem 0 0 0;
        // box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
        cursor: pointer;
        transition: box-shadow 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        
        .class-event-header {
            margin: 0;
            color: white;
            font-weight: 600;
            line-height: 1rem;
            font-size: .85rem;
            letter-spacing: .5px;
            text-align: start;
        }

        .event-status {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: 1px solid white;
            // background-color: white;
            // box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
        }
        .event-columns-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-height: .85rem;
        }

        .event-columns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            max-height: .85rem;
        }

        .class-event-summary {
            margin-top: .2rem;

            .event-students {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .student-names {
                    font-weight: 400;
                    font-size: .6rem;
                    letter-spacing: .5px;
                    margin: 0;
                    color: white;
                    line-height: 1rem;
                }

                .class-event-student-icon {
                    max-width: 10px;
                    max-height: 10px;
                    filter: invert(100%);
                }
            }
        }

        .class-event-subheader {
            margin: 0;
            color: white;
            font-weight: 400;
            line-height: .85rem;
            font-size: .6rem;
            letter-spacing: .5px;
            text-align: start;
        }

        h5 {
            margin: 0;
            padding: 0;
            line-height: 0;
        }
    }

    .class-event-container:hover {
        box-shadow: 6px 8px 8px rgba(0,0,0,0.2), 0 10px 8px rgba(0,0,0,0.06);
    }
}