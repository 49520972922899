.notifications-container {
    margin: 0;
    padding: 0;
    max-height: 17rem;
    overflow: scroll;

    .notification-item {
        list-style: none;
        margin-bottom: .7rem;
        
        .user {
            font-size: .9rem;
            font-weight: 500;
            margin: 0;
        }

        .content {
            font-weight: 300;
            margin: 0;
            font-size: .8rem;
            line-height: .9rem;
        }
    }
}