.session-details-container {
    height: 100%;
    width: 100%;

    .actions-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .add-student {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .add-btn {
                margin-left: 1rem;
            }
        }
    }

    .actions-container {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    }
}


// .session-details-container.active {
//     opacity: 100
// }