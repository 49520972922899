.change-status-btn {
    outline: none;
    border: none;
    cursor: pointer;

    .change-status-img {
        height: 1rem;
        width: 1rem;
        transition: height .2s, width .2s;
    }
}

.change-status-btn:active {
    .change-status-img {
        height: 1.1rem;
        width: 1.1rem;
    }
}

.invoice-grid-actions {
    .btn {
        margin-left: .2rem;
        margin-right: .2rem;
    }
    .icon {
        height: 1rem;
        width: 1rem;
        transition: height .2s, width .2s;
    }
}