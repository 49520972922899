.location-students-grid {
    width: 100%;
    height: 54vh;

    .header-container {
        width: 100%;
        height: 50px;
        background-color: white;
        display: flex;
        flex-direction: row;
        padding: 0 1.3rem 0 1.3rem;
    }

    .item-container {
        width: 100%;
        height: 30px;
        background-color: white;
        padding: 0 1.3rem 0 1.3rem;
        display: flex;
        flex-direction: row;
    }

    .item-column {
        width: 16%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item-text {
            font-weight: 300;
        }
    }

    .status-green {
        background-color: lightgreen;
    }

    .status-red {
        background-color: salmon;
    }
}