.student-list-item-container {

    .expand-btn {
        margin-right: 20px;
    }

    .list-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .list-item-properties {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .list-item-name {
                width: 150px;
                margin-left: 20px;
            }
            
            .list-item-secondary {
                width: 100px;
                z-index: 200;

                .attendance-controller {
                    display: flex;
                    flex-direction: row;
                }
            }

        }
    }
    
    .delete-btn{
        width: 30px;
        height: 30px;
        margin-right: 30px;
        display: flex;
    }
}

