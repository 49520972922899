@import '../../../style/utilities';

.mobile-session-details-container {
    height: 85vh;
    width: 100vw;
    background-color: #f4f4f4;
    overflow-y: scroll;
    padding: 2rem;

    .header-container {
        height: 15vh;
        display: flex;
        flex-direction: column;
        .info-container {
            .date-text,
            .info-text {
                color: darken(color(dark-font), 5%);
                margin: 0;
                padding: 0;
            }

            .date-text {
                font-size: 2.2rem;
            }

            .info-text {
                font-size: .8rem;
                font-weight: 400;
            }
        }

        .mobile-session-close-container {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }
    }

    .mobile-session-main-container {
        height: 55vh;
        width: 100%;
        overflow-y: scroll;
        
    }

    .save-btn-container {
        height: 5vh;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}