.progress-form-container {
    padding: 20px;



    .progress-form {
        display: flex;
        flex-wrap: wrap;

        .table {
            width: 100%;

            th {
                font-size: .7rem;
                font-weight: 400;
            }

            th:nth-child(2) {
                width: 10%;
            }

            th:nth-child(3),
            th:nth-child(4) {
                width: 10%;
            }
            th:last-child {
                width: 50%;
            }

            td, th {
                text-align: left;
            }
        }

        .input-field {
            margin-right: 4rem;

        }
    }

}