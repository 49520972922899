@import '../../../style/utilities.scss';

.widget-container {
    min-width: 20rem;
    min-height: 20rem;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: .5rem;
    position: relative;
    padding: 1.5rem;

    .widget-icon {
        position: absolute;
        top: 1rem;  
        left: 17rem;

        .icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    .widget-main {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
}