.subscription-grid-container {
    height: 70vh;
    width: 100%;
}

.cancel-subscription-button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 1rem;
    cursor: pointer;

    .cancel-icon {
        height: 1.2rem;
        width: 1.2rem;
        transition: height .3s, width .3s;
    }
}

.cancel-icon:hover {
    height: 1.6rem;
    width: 1.6rem;
}