#add-child-form {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 1rem;
    }
    
}

.form-action-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}