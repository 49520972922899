.birthdays-container {
    margin: 0;
    padding: 0;
    // position: relative;

    .header-container {
        // position: fixed;
        margin-bottom: .5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;

        // align-items: center;
        .month {
            width: 30%;
            text-align: center;
        }

        .arrow {
            margin-left: 1rem;
            margin-right: 1rem;
            cursor: pointer;
        }

        .header {
            font-size: .9rem;
            font-weight: 500;
            margin: 0;
        }
    }

    .birthdays {
        overflow: scroll;
        max-height: 15rem;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: .5rem;
            border-bottom: 1px dotted #bdc3c7;

            .name {
                margin: 0;
                padding: 0;
                font-size: .9rem;
                font-weight: 500;
            }

            .date {
                margin: 0;
                padding: 0;
                font-size: .9rem;
                font-weight: 300;
            }
        }
    }
}