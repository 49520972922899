.makeup-modal-container {
    height: 4rem;
    width: 8rem;
    position: absolute;
    bottom: 2rem;
    right: 3rem;
    z-index: 1000;
    border-radius: 2rem;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
    background-color: #ff6b6b;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s, background-color .3s;

    .makeup-modal-text {
        display: inline-block;
        color: white;
        margin: 0;
        padding: 0 1rem 0 1rem;
        text-align: center;
        font-weight: 500;
    }

    .text-active {
        display: none;
    }

    .makeup-modal-expanded {
        display: none;
        transition: display 1s;
        height:100%;
        width: 100%;
        // background-color: green;

        .header-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .header {

            }
        }
    }

    .expanded-active {
        display: inline-block;

        .header {
            margin: 0;
            padding: 0;
        }
    }
}

.makeup-modal-container:hover {
    background-color: #ee5253;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.16), 0 15px 12px rgba(0,0,0,0.16);
}

.modal-active {
    display: none;
    height: 90vh;
    width: 30vw;
    transition: height .3s, width .3s;
    border-radius: 1rem;
    background-color:#f5f5f5;
    padding: 2rem;
    cursor: default;
}

.modal-active:hover {
    background-color:#f5f5f5;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
}

