@import '../../../style/utilities.scss';

.layout-navbar-container {
    background-color: #fff;
    grid-column: 4/-1;
    grid-row: 1/2;
    padding: 2rem 2rem 2rem 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;

    .menu-container {

        .layout-navbar-avatar-container {
            display: flex;
    
            .title {
                margin-left: 1rem;
                margin-right: 2rem;
                text-transform: none;
                color: color(light-black);
            }
        }
    }

    .layout-navbar-logout-btn {
        min-width: 7rem;
    }

    .mobile-navbar-container {
        display: none;
    }

    @media screen and (max-width: $break-phone) {
        grid-column: 1/-1;
        margin-right: 0;


        .menu-container {
            display: none;
        }

        .mobile-navbar-container {
            width: 100%;
            // display: block;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .nav-icon3 {
                width: 3rem;
                height: 1.5rem;
                position: relative;
                // margin: 50px auto;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .5s ease-in-out;
                -moz-transition: .5s ease-in-out;
                -o-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
                cursor: pointer;
            }
              
            .nav-icon3 span {
                display: block;
                position: absolute;
                height: 3px;
                width: 80%;
                background: #d3531a;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
            }
              
            /* Icon 3 */

            .nav-icon3 span:nth-child(1) {
                top: 0px;
            }
            
            .nav-icon3 span:nth-child(2),.nav-icon3 span:nth-child(3) {
                top: 10px;
            }
            
            .nav-icon3 span:nth-child(4) {
                top: 20px;
            }
            
            .nav-icon3.open span:nth-child(1) {
                top: 8px;
                width: 0%;
                left: 50%;
            }
            
            .nav-icon3.open span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            
            .nav-icon3.open span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            
            .nav-icon3.open span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}


@media screen and (max-width: $break-phone) {
    .mobile-routes-container {
        grid-column: 1/-1;
        grid-row: 2/-1;


        .mobile-link-container {
            // height: 100%;
            width: 100%;
            // background-color: red;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 1rem;

            .mobile-link-icon {
                height: 80%;
                width: 15%;
                padding-left: 1rem;

                .mobile-link-icon-img {
                    width: 40%;
                    height: auto;
                    margin-left: 20%;
                }
            }

            .mobile-link-content {
                height: 8vh;
                width: 70%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .link-text {
                    color: black;
                }
            }
        }
    }
}