@import '../../../style/utilities.scss';


@media screen and (max-width: $break-phone) {
    .mobile-calendar-container {
        grid-column: 1/-1;
        grid-row: 2/-1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobile-calendar-header-container {
            height: 15vh;
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;

            .days-of-week-container {
                height: 85%;
                min-width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: space-around;

                .day,
                .current-day {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }

                .current-day,
                .plain-day {
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 1rem;

                    .current-day-text {
                        color: white;
                        font-weight: 400;
                    }

                    .now-day-text {
                        color: lightsalmon;
                        font-weight: 400;
                    }
                }

                .current-day {
                    background-color: lightsalmon;
                }
            }


            .display-date-controller-container {
                height: 10%;
                width: 90%;

            }
        }

        .mobile-calendar-main-container {
            height: 68vh;
            width: 100%;
            // background-color: #f4f4f4;
            padding: 1rem;
            margin-top: 2vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            // flex-wrap: wrap;
            overflow-y: scroll;
        }
    }
}