#subscription-form {

    .field {
        width: 27%;
    }

    .customer-information-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1.5rem;
    }

    .package-container {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .package-table {
            width: 100%;

            th, td {
                padding: .5rem;
                text-align: left;
                vertical-align: top;
            }

            td {
                font-size: .7rem;
                font-weight: 400;
            }

            td:first-child {
                // width: 20%
            }

            .add-package-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }

            .select-container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                min-height: 2rem;
                border-radius: 5px;
                border: 1px solid #bdc3c7;
                
            }

            .select-container select {
                border: none;
                background: transparent;
                padding: .5rem;
                width: 100%;
                outline: none;
            }

            .table-input {
                min-height: 2rem;
                width: 100%;
                border-radius: 5px;
                box-shadow: none;
                outline: none;
                border: 1px solid #bdc3c7;
                font-size: .8rem;
                padding-left: .5rem;
            }

            .minus-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: none;
                outline: none;
                border: none;
                color: #e74c3c;
                font-size: 1.3rem;
                background-color: white;
                border-radius: 50%;
                cursor: pointer;
            }

            .input-wrapper {
                width: 100%;
                border: 1px solid #bdc3c7;
                height: 2rem;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: .5rem;
            }

            .input {
                width: 100%;
                margin-left: .5rem;
                box-shadow: none;
                outline: none;
                border: none;
                font-size: .8rem;
            }
        }
    }

    .totals-container {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .table-input {
        min-height: 2rem;
        width: 90%;
        border-radius: 5px;
        box-shadow: none;
        outline: none;
        border: 1px solid #bdc3c7;
        font-size: .8rem;
        padding-left: .5rem;
    }
}