.students-grid-container {
    height: 70vh;
    width: 100%;

    .status-green {
        background-color: lightgreen;
    }

    .status-red {
        background-color: salmon;
    }
}