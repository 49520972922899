.mobile-event-container {
    min-height: 15vh;
    max-height: 15vh;
    width: 70%;
    margin-bottom: 2vh;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
    cursor: pointer;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .event-top {
        height: 70%;

        .mobile-event-status-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .event-status {
                width: 1rem;
                height: 1rem;
                border-radius: .5rem;
                border: 1px solid white;
            }
        }

        .mobile-event-name,
        .mobile-event-teacher {
            color: white;
            margin: 0;
            padding: 0;
        }

        .mobile-event-name {
            line-height: 1.3rem;
            font-size: 1.5rem;
            font-weight: 600;
        }

        .mobile-event-teacher {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }

    .event-bottom {
        height: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobile-event-time {
            color: white;
            font-weight: 400;
            font-size: 1.2rem;
        }

        .mobile-event-student-container{
            display: flex;
            flex-direction: row;
            align-items: center;

            .class-event-student-icon {
                max-width: 1rem;
                max-height: 1rem;
                filter: invert(100%);
            }

            .number-student-text {
                color: white;
                font-weight: 400;
                font-size: 1.2rem;
            }
        }
    }
}