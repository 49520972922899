@import '../../../style/utilities.scss';

.sub-list-container {
    width: 100%;
    height: auto;
    overflow: scroll;

    .parents-children-grid-container {
        height: 50vh;
        width: 100%;
    }
}

.payment-history-grid-container {
    height: 54vh;
    width: 100%;
}

.referral-form-container {
    display: flex;
    // padding: 0 4rem 0 4rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}