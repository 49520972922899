.balance-container {
    display: flex;
    flex-direction: row;
    align-items: centers;
    justify-content: space-between;

    .credit-action {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .credit-action-text {
            margin-right: 1rem;
        }
    }
}

.credit-pane {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .credit-input {
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 1em;
        padding: .5rem;
        outline: none;
    }
}

