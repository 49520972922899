@import '../../../style//utilities.scss';

.layout-component-wrapper {
    grid-column: 4/-1;
    grid-row: 2/-1;
    padding: .5rem 2rem 2rem 2rem;
    overflow: scroll;
    max-height: 100%;
    background-color: white;

    @media screen and (max-width: $break-phone) {
        grid-column: 1/-1;
    }

}

/*------------ ---------------*/
/*      START LIST HEADER     */
/*------------ ---------------*/

.list-actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 1rem;

    .action-container {
        display: flex;
        flex-direction: row;
        align-items: center; 
    }
    
}
/*------------ ---------------*/
/*       END LIST HEADER      */
/*------------ ---------------*/



.list-container {
    width: 100%;
    position: relative;
    max-height: 75vh;
    border-radius: 10px;
    overflow: scroll;
    background-color: #f1f1f1;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);

    .list-item-container {
        width: 100%;
        height: 40px;
        background-color: white;
        padding: 1.3rem 1.3rem 1.3rem 2rem;
        display: flex;
        flex-direction: row;

        .delete-item-column {
            width: 5%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .delete-item-icon {
                height: 60%;
                width: auto;
            }
        }
    
        .item-column {
            width: 19%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
            .item {
                font-weight: 400;
                font-size: .8rem;
                color: color(light-black);
            }
        }
    }
}

.list-container {
    opacity: 0;
    transition: opacity .3s;
}


.list-container.active {
    opacity: 100
}

.content-container {
    width: 100%;
    height: auto;
    border-radius: 20px;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
    opacity: 0;
    transition: opacity .3s;
    
    .header {
        padding-left: 1.3rem;
        color: color(black);
        box-shadow: 2px 2px 2px rgba(0,0,0,0.06), 0 4px 4px rgba(0,0,0,0.06);
    }

    .navbar-container {
       margin-top: 1rem;

        a {
            margin: 0 2rem 0 0;
        }

        .nested-active-route {
            padding: .4rem 1rem .4rem 1rem;
            font-weight: 600;
            color: color(red);
            border-radius: 30px;
            background-color: rgba(0,0,0,0.01);
            box-shadow: 2px 2px 2px rgba(0,0,0,0.06), 0 4px 4px rgba(0,0,0,0.06);
        }

        .parent-link {
            color: color(light-black);
            cursor: pointer;
        }
    }

    .main-content-container {
        padding: 1.3rem;
    }
}

.content-container.active {
    opacity: 100;
}


.details-form {
    padding-bottom: 2rem;
    // max-height: 55vh;
    overflow-y: scroll;

    .fields-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .field {
            width: 48%;
            display: flex;
        }

        .select-students-field {
            width: 100%;
            float: left;
        }

        .field-quarter {
            width: 50%;
            display: flex;
            align-items: center;
        }

        .field-start {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .switch-field {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .action-container {
        margin-top: 1rem;
    }
}


/*----------------------------*/
/*     START COLOR SELECT     */
/*----------------------------*/

.option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .option-color {
        min-width: 1rem;
        min-height: 1rem;
        border-radius: .5rem;
        margin-right: .5rem;
    }
}

/*----------------------------*/
/*      END COLOR SELECT      */
/*----------------------------*/

/*----------------------------*/
/*      START SUB LIST        */
/*----------------------------*/

.sub-list-container {
    width: 100%;
    height: auto;
    overflow: scroll;
    opacity: 0;
    transition: opacity .3s;

    .header-container {
        width: 100%;
        height: 50px;
        background-color: white;
        padding: 1.3rem;
        display: flex;
        flex-direction: row;
    }

    .item-column {
        width: 16%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .parent-item {
            font-weight: 300;
            font-size: .8rem;
        }
    }

    .item-container {
        width: 100%;
        height: 30px;
        background-color: white;
        padding: 0 1.3rem 0 1.3rem;
        display: flex;
        flex-direction: row;
        position: relative;

        .sub-item {
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
        }

        .sub-delete-item-column {
            position:absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .item-text {
            font-weight: 300;
        }
    }

    .table-list {
        width: 100%;
        border-spacing: 0px;

        td, th {
            text-align: left;
        }
        
        th {
            font-size: .85rem;
            font-weight: 700;
        }

        td {
            font-size: .85rem;
        }

        tr:nth-child(even) {
            background-color: #f5f5f5;
        }


        tr {
            height: 20px;
            width: 100%;
        }
        // th:nth-child(2) {
        //     width: 15%;
        // }

        // th:nth-child(3),
        // th:nth-child(4) {
        //     width: 15%;
        // }
        // th:last-child {
        //     width: 60%;
        // }
    }
}


.sub-list-container.active,
.details-form.active {
    opacity: 100
}

/*----------------------------*/
/*        END SUB LIST        */
/*----------------------------*/

