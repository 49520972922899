@import '../../../style//utilities.scss';

#layout-sidebar-container {
    // background-image: linear-gradient(to bottom, #ffffff, #a7d3ff, #a7d3ff, #a7d3ff, );
    background-color: #f5f5f5;
    grid-column: 1/4;
    grid-row: 1/3;
    position: relative;
    left: -100%;
    // padding: 2rem;
    // border-top-right-radius: 30px;
    // border-bottom-right-radius: 30px;
    box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
    z-index: 10;
    transition: left .5s;

    @media screen and (max-width: $break-phone) {
        display: none;
    }
    
    .layout-sidebar-logo-container {
        width: 100%;
        height: 100%;

        .layout-sidebar-logo {
            margin-top: 2rem;
            width: 70%;
            height: auto;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .layout-sidebar-links-container {
        margin-top: 5rem;
        width: 100%;

        .sidebar-link-container {
            height: 100%;
            width: 100%;
            // background-color: red;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 1rem;

            .sidebar-link-icon {
                height: 100%;
                width: 30%;
                padding-left: 1rem;

                .sidebar-link-icon-img {
                    width: 40%;
                    height: auto;
                    margin-left: 20%;
                }
            }

            .sidebar-link-content {
                height: 100%;
                width: 70%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .link-text {
                    color: black;
                }
            }

        }
    }

    .active, .active:hover {
        background-color: #fff;
    }

    .layout-sidebar-links-container > a {
        color: color(black);
        text-transform: uppercase;
        font-size: .8rem;
        font-weight: 600;
    }
}

#layout-sidebar-container.active {
    left: 0;
}