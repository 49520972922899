.edit-session-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 60%;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;

    .form-action-btn {
        margin-top: 2rem;
    }
}