@import '../../../style/utilities';

.mobile-session-student-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .student-name-container {
        width: 10%;

        .student-name {
            font-size: .8rem;
            font-weight: 500;
            color: darken(color(dark-font), 5%);
        }
    }

    .radio-group-container {
        width: 80%;

        .attendance-controller {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .expand-btn-container {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.mobile-table {
    width: 85%;

    .mobile-table-header {
        font-size: .8rem;
        text-align: left;
    }

}