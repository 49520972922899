@import '../../../style/utilities.scss';

.auth-container {
    font-family: sans-serif;
    min-width: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $break-phone) {
        min-width: auto;
    }

    .auth-logo-container {
        height: 10vh;
        width: 100vw;
        position: absolute;
        top: 0;
        background-image: linear-gradient(to right, color(blue), color(green), color(yellow));
        box-shadow: 5px 6px 7px rgba(0,0,0,0.06), 0 15px 12px rgba(0,0,0,0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;

        .auth-logo {
            height: 60%;
            width: auto;
        }
    }

    .login-wrapper {
        height: auto;
        width: 25rem;
        margin-top: 10rem;
    }
    
    .login-form {
        display: flex;
        flex-direction: column;
        padding: 0 15% 0 15%;
    
        .login-btn {
            margin-top: 5%;
        }

        // .social-links-container {
        //     margin-top: 1rem;
        //     margin-left: auto;
        //     margin-right: auto;
        //     width: 100%;
            // display: flex;
            // justify-content: space-between;

            .social-image {
                height: .9rem;
                width: .9rem;
                margin: 0;
                padding: 0;
                cursor: pointer;
            }

            .social-text {
                padding: 0;
                margin: 0;
                margin-left: .3rem;
                font-size: .8rem;
                text-transform: none;
                color: color(black);
            }

            .social-button {
                background-color: white;
                display: flex;
                justify-content: space-around;
                font-family: 'Roboto'
            }
        // }
    }
    
    .signup-forgot-password-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 15% 0 15%;
        margin-top: 8%;
    
        a {
            text-decoration: none;
            color: color(light-black);
            font-family: 'Roboto'
        }
    }
    
    .signup-wrapper {
        height: auto;
        width: 25rem;
    }

    .approval-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .header {
            color: color(black);
            margin: 0 0 .1rem 0;
            text-align: center;
        }

        .login-btn {
            margin-top: 4rem;
            max-width: 10rem;
        }
    }
}