.invoice-form-header-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-left: 2rem;

    .field-container {
        margin-right: 2rem;
        width: 20%;

        .field-header {
            margin: .5rem 0 .5rem 0;
        }

        
        .input {
            display: block;
            padding: .5em;
            line-height: 1.3;
            border: 1px solid #ccc;
            width: 100%;
            font-size: 12px;
            font-family: sans-serif;
            color: #777;
            max-height: 100%;
            appearance: none;
            outline: none;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: white;
        }
    }
}

.line-items-container {

    .line-item-container {
        display: flex;
        width: 100%;
        height: auto;
        align-items: flex-start;
        margin-top: 1em;
        margin-bottom: 1em;
        // justify-content: flex-start;

        .delete-col {
            width: 20px;
            height: 20px;
            margin-right: 1rem;

            .btn {
                margin-top: 16px;
                border: none;
                outline: none;
                cursor: pointer;

                .img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .field-container {
            margin-right: 1rem;
            width: 20%;

            .field-header {
                margin: .5rem 0 .5rem 0;
            }

            .input {
                display: block;
                padding: .5em;
                line-height: 1;
                border: 1px solid #ccc;
                width: 100%;
                font-size: 12px;
                font-family: sans-serif;
                color: #777;
                max-height: 100%;
                appearance: none;
                outline: none;
                border-radius: 5px;
                box-sizing: border-box;
                background-color: white;

                .option {

                }
                    
            }

            .input.small {
                width: 70px;
            }
        }

        .field-container.small {
            width: 70px;
        }
    }
}

.add-line-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
}

.invoice-footer-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    padding-right: 4rem;

    .invoice-footer-notes {

        .invoice-footer {
            display: block;
            padding: .5em;
            line-height: 1;
            border: 1px solid #ccc;
            width: 100%;
            font-size: 12px;
            font-family: sans-serif;
            color: #777;
            max-height: 100%;
            appearance: none;
            outline: none;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: white;

        }
    }

    .invoice-totals {
        display: flex;
        flex-direction: column;

        .totals-container {
            width: 15em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0;
            margin-bottom: 1rem;

            .subheader {
                font-weight: 400;
                margin: 0
            }

            .header {
                margin: 0;

            }
        }

        .send-btn {
            margin-top: 1em;
        }
    }
}