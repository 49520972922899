$colors: (
    red: #ff6b6b,
    green: #1dd1a1,
    blue: #48dbfb,
    black: #2d3436,
    yellow: #f6e58d,
    light-black: #7f8c8d,
    grey: #f1f2f6,
    purple: #a29bfe,
    dark-font: #5C5C5C,
);

$break-phone: 500px;
$break-phone-land: 800px;
$break-ipad: 1140px;

@function color($color-name) {
    @return map-get($colors, $color-name)
};

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    font-family: 'Aktiv Grotesk', sans-serif;
    letter-spacing: 1;
}

a {
    text-decoration: none; 
    font-family: 'Aktiv Grotesk', sans-serif !important;
    color: color(light-black);
}

.loading-circle-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}