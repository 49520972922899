@import '../style/utilities.scss';

#add-item-action-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    #slider-container {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        // background-color: rgba(0,0,0, 0.1);
        z-index: 1000;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: default;
        // transition: opacity .1s cubic-bezier(0.075, 0.82, 0.165, 1);
    
        #slider-inner-container {
            height: 100%;
            width: 50%;
            background-color: white;
            padding: 0 2rem 0 2rem;
            display: flex;
            flex-direction: column;
            // justify-content: flex-start;
            align-items: flex-start;
            transition: transform .7s cubic-bezier(0.075, 0.82, 0.165, 1);
            box-shadow: -5px 2px 7px rgba(0,0,0,0.06), -10px 2px 12px rgba(0,0,0,0.06);

            .header-container {
                height: 10rem;
                display: flex;
                flex-direction: column;

                .slide-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 2rem 0 0 0;
                
                    .slide-header-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 100
                    }

                    .header {
                        color: color(black);
                        font-size: 2rem;
                        box-shadow: none;
                        margin: 0;
                        padding: 0;
                        border-bottom: none;
                    }
                }

                .slide-subheader {
                   .subheader {
                       padding: 0;
                       margin: 0 0 0 3.2rem;
                       color: color(black);
                       font-weight: 300;
                   }
                }
            }

            .slide-content {
                width: 100%;
                // margin-top: 7rem;
                overflow-y: scroll
            }
        }
    }

    #slider-container.overlay-hide {
        z-index: -200;
    }
    
    #slider-container.overlay-show {
        opacity: 1;
        z-index: 200;
    }
}

.hide {
    transform: translate3d(70vw, 0, 0);
    // z-index: -200;
}

.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
}