#add-parent-form {
    width: 100%;
    display: flex;
    position: relative;
    
    .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .add-parent-form-column {
        margin: 1rem 1rem 1rem 0;
    }
}

.form-action-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
